import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { NfcLocationsDescription } from "@src/appV2/Shifts/NfcHashes/components/NfcLocationsDescription";
import { ReadingFailureType } from "@src/appV2/Shifts/NfcHashes/NfcDialog/types";
import type { NfcTagRequest } from "@src/appV2/Shifts/Shift/types";

export interface UnknownErrorContentProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
  attemptNumber: number;
  failureType: ReadingFailureType;
  errorCode?: string;
}

export function UnknownErrorContent(props: UnknownErrorContentProps): JSX.Element {
  const { facilityName, nfcTagRequests, attemptNumber, failureType, errorCode } = props;
  return (
    <>
      <Text bold variant="h2">
        ⚠️ Error: other NFC error
      </Text>
      <Text variant="h4">
        {attemptNumber >= 3 &&
        failureType === ReadingFailureType.OTHER &&
        errorCode === "SCAN_FAILED"
          ? "Something went wrong with your NFC sensor. Please try restarting your device."
          : "Something went wrong. Please try again."}
      </Text>

      <NfcLocationsDescription
        nfcTagRequests={nfcTagRequests}
        facilityName={facilityName}
        textVariant="h4"
        listTextVariant="body1"
        isLocationBold={failureType === ReadingFailureType.SCAN_TIMEOUT}
      />

      <Text variant="h4">
        If you still need help, please{" "}
        <ExternalLink to="https://youtu.be/WuXARGIxdGg" fontWeight="bold">
          <strong>watch this quick NFC tutorial</strong>
        </ExternalLink>
        .
      </Text>
    </>
  );
}
