import { NfcHashValidationAction } from "../api/types";

export function clockActionToReadableString(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "Use a Clipboard NFC Time Clock to clock in",
    [NfcHashValidationAction.CLOCK_OUT]:
      "Final step: clock out using a Clipboard NFC Time Clock to get paid!",
    [NfcHashValidationAction.BREAK_START]: "Use a Clipboard NFC Time Clock to start your break",
    [NfcHashValidationAction.BREAK_END]: "Use a Clipboard NFC Time Clock to end your break",
    [NfcHashValidationAction.SKIP_BREAK]: "Use a Clipboard NFC Time Clock to skip your break",
  }[clockAction];
}

export function clockActionToReadableVerb(clockAction: NfcHashValidationAction) {
  return {
    [NfcHashValidationAction.CLOCK_IN]: "clock in",
    [NfcHashValidationAction.CLOCK_OUT]: "clock out",
    [NfcHashValidationAction.BREAK_START]: "break start",
    [NfcHashValidationAction.BREAK_END]: "break end",
    [NfcHashValidationAction.SKIP_BREAK]: "break skip",
  }[clockAction];
}
